<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 操作记录 </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :loading="loading"
        :rowKey="(item,index) => index"
        :columns="columns"
        :scroll="{ x: 1200 }"
        :data-source="tableData"
      >
        <template slot="status" slot-scope="text">
          <span :class="text == '异常' ? 'state_color_red' : 'state_color_green'">{{text}}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "操作时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作行为",
    align: "center",
    dataIndex: "content",
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
    };
  },
  // 事件处理器
  methods: {
    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/exam/abnor-oper/list",
        params: {
          examineeId: this.$route.query.examineeId,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data;
          this.total = res.data.length;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .line-item:last-child {
    margin-bottom: 0;
  }
  .right {
    flex: 1;
    height: 40px;
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
.table-template {
  margin-top: 0;
}
/deep/ .ant-modal-footer {
  text-align: center;
}
.error{

}
</style>
